import * as React from "react"
import { Layout } from "../templates/layout";
import { Link, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"


const ArchivePage = ({ data }) => {
  const { edges: posts } = data.allMarkdownRemark
  return (
    <Layout>
      <div>
        <div className="pt-10 text-center">
          <h1 className="font-extrabold text-5xl md:text-6xl leading-tighter tracking-tight mb-2">Without Boxes</h1>
          <p className="font-serif text-xl text-olive-400">Ordinary people. Extraordinary stories.</p>
          <p className="mt-4">Read the archives.</p>
        </div>
        <div className="mt-10 max-w-3xl mx-auto">
          {posts
            .filter(post => post.node.frontmatter.title.length > 0)
            .map(({ node: post }) => {
              let excerpt = post.frontmatter._yoast_wpseo_metadesc ? post.frontmatter._yoast_wpseo_metadesc : post.frontmatter.excerpt
              let thumbnail = getImage(post.frontmatter.thumbnail)
              return (
                <div className="mb-6 grid grid-cols-3 lg:gap-x-12 gap-x-6 border-b pb-6" key={post.id}>
                  <div className="col-auto">
                    {thumbnail && <GatsbyImage image={thumbnail} alt={post.frontmatter.title} className="object-cover aspect-square rounded grayscale hover:grayscale-0 lg:ml-0 ml-4" />}
                  </div>
                  <div className="col-span-2 lg:pr-0 pr-4">
                    <h2 className="font-bold text-xl">
                      <Link to={"/archives" + post.frontmatter.permalink}>{post.frontmatter.title}</Link>
                    </h2>
                    <p className="text-sm">by {post.frontmatter.author}</p>
                    <p className="mt-4">{excerpt}</p>
                  </div>
                </div>
              )
            })}
        </div>
      </div>
    </Layout>
  )
}

export default ArchivePage

export const Head = () => <title>Without Boxes Blog Archives</title>

export const pageQuery = graphql`
  query IndexQuery {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
        limit: 1000
        filter: {frontmatter: {status: {ne: "draft"}}}
    ) {
      edges {
        node {
          excerpt(pruneLength: 250)
          id
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            permalink
            author
            _yoast_wpseo_metadesc
            excerpt
            thumbnail {
              childImageSharp {
                gatsbyImageData(
                  width: 600
                  placeholder: BLURRED
                  formats: [AUTO, WEBP]
                )
              }
            }
          }
        }
      }
    }
  }
`